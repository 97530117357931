<template>
  <div id="contact-us" class="container position-relative pt-5">
    <div class="row px-md-5 gx-md-5 py-5">
      <div class="col-sm-7 py-5 d-flex flex-column justify-content-center">
        <h3>
          Si quieres bienestar disfruta de la
        </h3>
        <h1>arquitectura EC Diseños…</h1>
        <h4 class="float-end pt-md-5">
          <span>Síguenos en las redes</span>
          &nbsp; 
          &nbsp; 
          &nbsp; 
          <a 
            v-if="this.$parent.$parent.settings?.facebook"
            :href="`https://facebook.com/${this.$parent.$parent.settings?.facebook}`"
            target="_blank"
            class="text-main-hover"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a 
            v-if="this.$parent.$parent.settings?.twitter"
            :href="`https://twitter.com/${this.$parent.$parent.settings?.twitter}`"
            target="_blank"
            class="text-main-hover"
          >
            
            &nbsp;<font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a 
            v-if="this.$parent.$parent.settings?.instagram"
            :href="`https://instagram.com/${this.$parent.$parent.settings?.instagram}`"
            target="_blank"
            class="text-main-hover"
          >
            &nbsp;<font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a 
            v-if="this.$parent.$parent.settings?.whatsapp"
            :href="`https://wa.me/${this.$parent.$parent.settings?.whatsapp}`"
            target="_blank"
            class="text-main-hover"
          >
            &nbsp;<font-awesome-icon :icon="['fab', 'whatsapp']" />
          </a>
        </h4>
      </div>
      <div class="col-md-4 px-sm-5">
        <div class="form-group">
          <label for="name">Nombre *</label>
          <input
            id="name"
            name="name"
            class="form-control text-light"
            type="text" 
            style="background: transparent"
            v-model="item.name"
            :class="errors['name'] ? 'is-invalid' : ''"
            maxlength="60"
          >
          <template v-if="errors['name']">
            <div
              v-for="error in errors['name']"
              :key="error"
              class="invalid-feedback"
            >
              {{ error }}
            </div>
          </template>
        </div>
        <div class="form-group">
          <label for="email">Correo *</label>
          <input
            id="email"
            name="email"
            class="form-control text-light"
            type="email"
            style="background: transparent"
            v-model="item.email"
            :class="errors['email'] ? 'is-invalid' : ''"
            maxlength="60"
          >
          <template v-if="errors['email']">
            <div
              v-for="error in errors['email']"
              :key="error"
              class="invalid-feedback"
            >
              {{ error }}
            </div>
          </template>
        </div>
        <div class="form-group">
          <label>Mensaje *</label>
          <textarea 
            class="form-control text-light"
            style="background: transparent"
            rows="4"
            v-model="item.message"
            :class="errors['message'] ? 'is-invalid' : ''"
            maxlength="1080"
          ></textarea>
          <template v-if="errors['message']">
            <div
              v-for="error in errors['message']"
              :key="error"
              class="invalid-feedback"
            >
              {{ error }}
            </div>
          </template>
        </div>
      </div>
      <div class="col-md-1 px-sm-5">
        <div class="form-group d-flex flex-column align-items-start h-100">
          <input id="submit" class="btn bg-light fw-bold float-end px-4 mt-auto mb-3" type="submit" value="Enviar" @click.prevent="send()">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <iframe 
          class="w-100"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3923.1117199917617!2d-66.87846156568921!3d10.491858520328897!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58e08ea56191%3A0x5716edbeb0df3e17!2sTorre%20Am%C3%A9rica%2C%20Avenida%20Venezuela%2C%20Caracas%201050%2C%20Distrito%20Capital!5e0!3m2!1ses!2sve!4v1642814183952!5m2!1ses!2sve"
          width="800"
          height="600"
          style="border:0;"
          allowfullscreen=""
          loading="lazy">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  data () {
    return {
      item: {
        name: undefined,
        email: undefined,
        organization: undefined,
        message: undefined
      },
      errors: {}
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img4')
    this.$emit('loading', false)
  },
  methods: {
    send() {
      this.$emit('loading', true)
      axios.post(`${process.env.VUE_APP_API_URL}/api/contacts`, this.item)
        .then(response => {
          this.$forceUpdate()

          this.errors = {}

          this.item = {
            name: undefined,
            email: undefined,
            organization: undefined,
            message: undefined
          }
          this.$emit('loading', false)

          Swal.fire({
            title: 'Enviado',
            text: 'Sus datos han sido enviados de forma satisfactoria.',
            icon: 'success',
            confirmButtonText: 'Cerrar'
          })
        })
        .catch(error => {
          this.errors = error.response?.data?.errors || {}
          this.$emit('loading', false)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
#contact-us {
  color: white;
}
a {
  color: inherit;
  text-decoration: none;
}
</style>