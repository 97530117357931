<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Noticias</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Artículos</li>
              <li class="breadcrumb-item">Noticias</li>
              <li class="breadcrumb-item active">Formulario</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Formulario</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.news.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>

                  <button
                    class="btn btn-tool btn-secondary"
                    @click="model.reload()"
                  >
                    <font-awesome-icon icon="backspace" />
                    Resetear
                  </button>

                  <button
                    class="btn btn-tool btn-primary"
                    @click="save()"
                  >
                    <font-awesome-icon icon="save" />
                    Guardar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <form 
                  novalidate="true"
                >
                  <div class="row">
                    <div class="form-group col-8">
                      <div
                        v-if="model.attributes.images"
                        class="col"
                      >
                        <img :src="model.attributes.images[0]?.path" class="mb-3">
                      </div>
                    </div>

                    <div class="form-group col-4">
                      <label for="file">Imagen</label>
                      <input
                        type="file"
                        class="form-control form-control-border"
                        id="file"
                        name="file"
                        placeholder="Imagen"
                        accept="image/*"
                        :class="model.errors['file'] ? 'is-invalid' : ''"
                        @change="model.attributes.file = $event.target.files[0]"
                      >
                      <template v-if="model.errors['file']">
                        <div
                          v-for="error in model.errors['file']"
                          :key="error"
                          class="text-danger"
                        >
                          <small>{{ error }}</small>
                        </div>
                      </template>
                    </div>

                    <div class="form-group col-8">
                      <label for="title">Título: </label>
                      <input
                        type="text"
                        class="form-control form-control-border"
                        id="title"
                        name="title"
                        placeholder="Título"
                        v-model="model.attributes.title"
                        :class="model.errors['title'] ? 'is-invalid' : ''"
                      >
                      <template v-if="model.errors['title']">
                        <div
                          v-for="error in model.errors['title']"
                          :key="error"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </template>
                    </div>

                    <div class="form-group col-4">
                      <label for="category_id">Categoría: </label>
                      <select
                        class="form-control form-control-border"
                        id="category_id"
                        name="category_id"
                        v-model="model.attributes.category_id"
                        :class="model.errors['category_id'] ? 'is-invalid' : ''"
                      >
                        <option
                          v-for="item in categories"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <template v-if="model.errors['category_id']">
                        <div
                          v-for="error in model.errors['category_id']"
                          :key="error"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </template>
                    </div>

                    <div class="form-group col-8">
                      <label for="email">Contenido: </label>
                      <template v-if="model.errors['content']">
                        <div
                          v-for="error in model.errors['content']"
                          :key="error"
                          class="text-danger"
                        >
                          <small>{{ error }}</small>
                        </div>
                      </template>
                      <Editor
                        api-key="no-api-key"
                        :init="{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount image code'
                          ],
                          file_picker_types: 'image',
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | link image | code | help',
                          automatic_uploads: true,
                          images_upload_url: 'http://google.co.ve'
                        }"
                        v-model="model.attributes.content"
                      />
                    </div>

                    <div class="form-group col-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="model.attributes.highlighted"
                          id="highlighted"
                          name="highlighted"
                          true-value="1"
                          false-value="0"
                        >
                        <label class="form-check-label" for="highlighted">
                          Destacado
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios"
import Editor from '@tinymce/tinymce-vue'
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  components: {
    Editor
  },
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        title: undefined,
        content: undefined,
        category_id: undefined,
        images: [],
        file: undefined,
        highlighted: '0',
        type: 'articulo',
        user_id: JSON.parse(localStorage.getItem('user'))?.user?.id,
      }),
      categories: []
    }
  },
  mounted () {
    this.model
      .from('articles')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .relations(['category', 'images'])

    axios.get(`${process.env.VUE_APP_API_URL}/api/categories?order_by=name:ASC&per_page=0`)
      .then(response => {
        this.categories = response.data.data

        if (this.$route.params.id) {
          this.model
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          const id = response.data[this.model.primaryKey];

          this.model.load(id)

          this.$router.push({ name: 'admin.news.update', params: { id: id } })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.saveFormData(success, () => {
        this.$emit('loading', false)
      })
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>