<template>
  <div class="container text-light px-sm-5" v-if="article">
    <div class="row px-sm-5 pt-5">
      <div id="framed-container"  class="offset-sm-1 col-sm-10 row px-sm-5 pt-5">
        <div
          v-if="article.images"
          id="slider"
          class="position-relative"
        >
          <span @click="prevSlide()" class="slider-btn btn-prev position-absolute top-50 translate-middle">
            <svg width="23" height="39" viewBox="0 0 23 39" style="transform: scale(1);">
              <path class="slideshow-arrow" d="M154.994,259.522L153.477,261l-18.471-18,18.473-18,1.519,1.48L138.044,243Z" transform="translate(-133 -225)"></path>
            </svg>
          </span>
          <span @click="nextSlide()" class="slider-btn btn-next position-absolute top-50 translate-middle">
            <svg width="23" height="39" viewBox="0 0 23 39" style="transform: scale(1);">
              <path class="slideshow-arrow" d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z" transform="translate(-855 -230)"></path>
            </svg>
          </span>
          <transition name="fade" 
            v-for="(image, key) in article.images"
            :key="image.id"
          >
            <img
              class="article-image slider-image"
              :class="key != current ? 'image-hidden' : ''"
              :src="image.path"
            >
          </transition>
        </div>
        <div class="col-lg-4 py-5">
          <h3 class="text-main col-12">{{ article.title }}</h3>
          <router-link class="text-light" :to="{name: 'portafolio'}">&lt; Volver al portafolio</router-link>
        </div>
        <div class="offset-lg-1 col-lg-7 pt-5">
          <div id="article-content" v-html="article.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  data () {
    return {
      moment: moment,
      article: [],
      current: 0,
      timeout: null
    }
  },
  methods: {
    nextSlide() {
      const next = this.current + 1;
      const length = Object.values(this.article.images).length

      if (length > next && 0 <= next ) {
        this.current = next
      } else {
        this.current = 0
      }

      this.loop();
    },
    prevSlide() {
      const next = this.current - 1;
      const length = Object.values(this.article.images).length

      if (length > next && 0 <= next ) {
        this.current = next
      } else {
        this.current = length - 1
      }

      this.loop();
    },
    loop() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.nextSlide();
      }, 10000)
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img6')
    this.$emit('loading', true)

    this.loop();

    axios
      .get(`${process.env.VUE_APP_API_URL}/api/articles/${this.$route.params.id}?with=images`)
      .then((response) => {
        this.article = response.data
        if (response.data.slug !== this.$route.params.slug) {
          this.$router.push({ name: 'public.not_found' })
        }
        this.$emit('loading', false)
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error);
      })
  }
}
</script>

<style scoped lang="scss">
.slider-btn {
  font-size: 80px;
  cursor: pointer;
  z-index: 100;
  filter: drop-shadow(0 1px .15px #B2B2B2);
}
.btn-prev {
  left: 52px;
}
.btn-next {
  right: 28px;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 1s ease;
  position: absolute;
  top: 0;
  left: 0;
}
.fade-enter-from,
.fade-leave-to,
.image-hidden {
  opacity: 0;
  transition: opacity 1s ease;
  position: absolute;
  top: 0;
  left: 0;
}
#slider {
  display: inline-block;
  overflow: hidden;
}
</style>