<template>

  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Roles</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Acceso</li>
              <li class="breadcrumb-item active">Roles</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">Filtros</div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-sm-4">
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="name"
                      name="name"
                      placeholder="Nombre"
                      @change="model.where('name', 'like', $event.target.value)"
                    >
                  </div>
                </div>
                <button
                  class="btn btn-primary float-right"
                  @click.prevent="model.setPage(1).all()"
                >
                  <font-awesome-icon icon="filter" />
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Listado</h3>

                <div class="card-tools">
                  <router-link
                    class="btn btn-tool btn-primary"
                    :to="{ name: 'admin.access.roles.create' }"
                  >
                    <font-awesome-icon icon="plus" />
                    Agregar
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <nav aria-label="navigation" v-if="model.pagination?.total > 0">
                  <small>
                    Mostrando desde <b>{{ model.pagination.from }}</b> hasta <b>{{ model.pagination.to }}</b>, de un total de <b>{{ model.pagination.total }}</b> registro(s).
                  </small>
                  <ul class="pagination" v-if="model.pagination.last_page > 1">
                    <template
                      v-for="link in model.pagination.links"
                    >
                      <li
                        class="page-item"
                        :key="link"
                        :class="link.active ? 'active' : ''"
                        v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
                      >
                        <a
                          v-if="link.label !== '...'"
                          href="#"
                          :aria-label="link.label"
                          nowrap
                          class="page-link"
                          @click.prevent="link.label === '...' || model.setPage(link.label).all()"
                        >
                          {{ link.label }}
                        </a>
                        <a 
                          v-else
                          class="page-link disable"
                        >
                          {{ link.label }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </nav>
                <hr>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-hover">
                    <thead class="bg-primary">
                      <tr class="text-center">
                        <th @click="model.orderBy('id').setPage(1).all()" nowrap>
                          ID&nbsp;&nbsp;
                          <font-awesome-icon :icon="getSortIcon('id')" />
                        </th>
                        <th @click="model.orderBy('name').setPage(1).all()" nowrap>
                          Nombre&nbsp;&nbsp;
                          <font-awesome-icon :icon="getSortIcon('name')" />
                        </th>
                        <th nowrap>Acciones</th>
                      </tr>
                    </thead>
                    <tbody v-if="model.items.length > 0">
                      <tr v-for="item in model.items" :key="item.id">
                        <td nowrap>{{ item.attributes.id }}</td>
                        <td nowrap>{{ item.attributes.name }}</td>
                        <td class="text-center" nowrap>
                          <router-link
                            class="text-primary table-action"
                            :to="{ name: 'admin.access.roles.update', params: {id: item.attributes[item.primaryKey]} }"
                          >
                            <font-awesome-icon icon="edit" />
                          </router-link>
                          <a
                            class="text-danger table-action"
                            href="#"
                            @click.prevent="remove(item)"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="4">No hay resultados.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr>
                <nav aria-label="navigation" v-if="model.pagination?.total > 0">
                  <small>
                    Mostrando desde <b>{{ model.pagination.from }}</b> hasta <b>{{ model.pagination.to }}</b>, de un total de <b>{{ model.pagination.total }}</b> registro(s).
                  </small>
                  <ul class="pagination" v-if="model.pagination.last_page > 1">
                    <template
                      v-for="link in model.pagination.links"
                    >
                      <li
                        class="page-item"
                        :key="link"
                        :class="link.active ? 'active' : ''"
                        v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
                      >
                        <a
                          v-if="link.label !== '...'"
                          href="#"
                          :aria-label="link.label"
                          nowrap
                          class="page-link"
                          @click.prevent="link.label === '...' || model.setPage(link.label).all()"
                        >
                          {{ link.label }}
                        </a>
                        <a 
                          v-else
                          class="page-link disable"
                        >
                          {{ link.label }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import moment from "moment"
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  data () {
    return {
      moment: moment,
      model: new ActiveRecordService()
    }
  },
  mounted () {
    this.$emit('loading', true)

    this.model
      .from('roles')
      .orderBy('id', 'ASC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()
  },
  methods: {
    remove(item) {
      if (!confirm("¿Está seguro(a) que desea eliminar este registro?")) {
        return;
      }

      this.$emit('loading', true)

      item.delete(() => {
        this.model.all()
      })
    },
    getSortIcon(name) {
      if (this.model.sort.column == name) {
        return {
          prefix: 'fas',
          iconName: this.model.sort.order == 'ASC' ? 'sort-up' : 'sort-down',
        }
      }

      return {
        prefix: 'fas',
        iconName: 'sort'
      }
    }
  },
}
</script>