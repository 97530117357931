<template>
  <div id="about-us" class="container-fluid position-relative p-0 bg-none">
    <img
      class="w-100 position-absolute d-none d-md-block"
      src="@/assets/img/blog/mod_white.png"
      style="
        top: -150px;
        right: -350px;
        max-width: 700px;
        z-index: 1000;
      "
    >
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-8 d-flex flex-column justify-content-center">
          <p class="px-sm-5 fs-3 pt-sm-5 pt-md-0">
            EC Diseños está conformada por un equipo de profesionales interdisciplinarios, comprometidos con la excelencia, la creatividad, la innovación y el análisis de nuestras obras.
          </p>
        </div>
      </div>
    </div>

    <div class="d-none d-sm-block mt-5">
      <img class="w-100 p-0" src="@/assets/img/cintillo.png">
    </div>

    <div class="container-fluid bg-black">
      <div class="container pt-5 pb-5">
        <div class="row p-sm-5 gx-5 pb-5">
          <div class="col-md-4 p-sm-5  px-md-5">
            <p class="fs-1 text-center">Misión</p>
            <p>Somos una empresa venezolana del 
  sector de la Arquitectura y 
  Construcción, enfocada en crear
  espacios para el bienestar de 
  nuestros clientes, a través del diseño 
  arquitectónico, construcción 
  y remodelación. Generando proyectos 
  eficientes, para ser ejecutados 
  mediante un servicio profesional con 
  altos estándares de calidad y 
  rentabilidad, optimizando la inversión y el 
  tiempo de ejecución</p>
          </div>
          <div class="col-md-4 p-sm-5  px-md-5">
            <p class="fs-1 text-center">Visión</p>
            <p>Ser una empresa de Obras y 
  Proyectos de excelencia, estable y
  reconocida por su ética y valores. Ofreciendo calidad en el servicio a través de 
  una organización ligera, 
  eficiente y eficaz. Comprometidos con el 
  crecimiento profesional y personal de 
  nuestro equipo de trabajo</p>
          </div>
          <div class="col-md-4 p-sm-5  px-md-5">
            <p class="fs-1 text-center">Valores</p>
            <p class="text-center">
              Excelencia
              <br>
              Respeto
              <br>
              Servicio al Cliente
              <br>
              Calidad
              <br>
              Tiempo de ejecución
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-black">
      <div class="container py-sm-5">
        <div class="row">
          <div class="col-sm-6 d-none d-md-block">
            <img class="w-100 p-0" src="@/assets/img/blog/about_02.png">
          </div>
          <div class="col-md-6">
            <h2 class="py-3 text-center">{{ item?.value }}</h2>
            <span v-html="item?.description"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-light py-5">
      <div class="container py-5">
        <div class="row">
          <div class="col-md-4 d-none d-md-block">
            <img class="w-100 p-0" src="@/assets/img/blog/mod_black.png">
          </div>
          <div class="col-md-8 d-flex flex-column justify-content-center">
            <p class="fs-5">
              La arquitectura es para disfrutarla generando BIENESTAR en nuestra vida, por eso nos enfocamos en acompañarlos y asesorarlos en el diseño y construcción de los espacios para vivir, trabajar y/o recrearse.
              <br>
              Te brindo la oportunidad de visualizar y crear a través de mis conocimientos y experiencias, para hacer posible la materialización de tu sueño que se convierte en “sueño común
            </p>
          </div>
        </div>
        <p class="fs-4 text-center pt-5">Si quieres bienestar disfruta de la arquitectura EC Diseños…</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data () {
    return {
      item: undefined
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img2')
    this.$emit('loading', true)

    axios
      .get(`${process.env.VUE_APP_API_URL}/api/settings/5`)
      .then((response) => {
        this.item = response.data
        this.$emit('loading', false)
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error);
      })
  }
}
</script>

<style lang="scss" scoped>
#about-us {
  color: white;
}
</style>