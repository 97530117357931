<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Usuarios</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Acceso</li>
              <li class="breadcrumb-item">Usuarios</li>
              <li class="breadcrumb-item active">Formulario</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Formulario</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.access.users.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>

                  <button
                    class="btn btn-tool btn-secondary"
                    @click="model.reload()"
                  >
                    <font-awesome-icon icon="backspace" />
                    Resetear
                  </button>

                  <button
                    class="btn btn-tool btn-primary"
                    @click="save()"
                  >
                    <font-awesome-icon icon="save" />
                    Guardar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="name">Nombre: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="name"
                      name="name"
                      placeholder="Nombre"
                      v-model="model.attributes.name"
                      :class="model.errors['name'] ? 'is-invalid' : ''"
                    >
                    <template v-if="model.errors['name']">
                      <div
                        v-for="error in model.errors['name']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="email">Correo: </label>
                    <input
                      type="email"
                      class="form-control form-control-border"
                      id="email"
                      name="email"
                      placeholder="Correo"
                      v-model="model.attributes.email"
                      :class="model.errors['email'] ? 'is-invalid' : ''"
                    >
                    <template v-if="model.errors['email']">
                      <div
                        v-for="error in model.errors['email']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="roles">Role(s): </label>
                    <select
                      class="form-control form-control-border"
                      id="role_ids"
                      name="role_ids"
                      placeholder="Roles"
                      v-model="model.attributes.role_ids"
                      multiple="true"
                    >
                      <option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role.id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="password">Contraseña: </label>
                    <input
                      type="password"
                      class="form-control form-control-border"
                      id="password"
                      name="password"
                      placeholder="Contraseña"
                      v-model="model.attributes.password"
                      :class="model.errors['password'] ? 'is-invalid' : ''"
                      autocomplete="off"
                    >
                    <template v-if="model.errors['password']">
                      <div
                        v-for="error in model.errors['password']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="password_confirmation">Confirmación: </label>
                    <input
                      type="password"
                      class="form-control form-control-border"
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder="Confirmación"
                      v-model="model.attributes.password_confirmation"
                      autocomplete="off"
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios"
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  data () {
    return {
      roles: [],
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        email: undefined,
        password: undefined,
        password_confirmation: undefined,
        role_ids: [],
      })
    }
  },
  mounted () {
    this.model
      .from('users')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter((response) => {
        this.$emit('loading', false)

        this.model.attributes.role_ids = response.data.roles.map(role => role.id)
      })

    axios.get(`${process.env.VUE_APP_API_URL}/api/roles?per_page=0`)
      .then(response => {
        this.roles = response.data.data

        if (this.$route.params.id) {
          this.model
            .relations(['roles'])
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
  },
  destroyed() {
    console.log('destroyed');
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          this.$router.push({ name: 'admin.access.users.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  }
}
</script>