<template>
  <div class="container text-light px-sm-5" v-if="articles.items.length > 0">
    <div class="row px-5 py-5">
      <div
        class="article offset-sm-1 col-sm-10"
        v-for="article in articles.items" :key="article.id"
      >
        <router-link
          v-if="article.attributes.images[0]?.path"
          class="text-light px-sm-5 pt-5"
          :to="{name: 'portafolio.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
        >
          <img
            class="article-image"
            :src="article.attributes.images[0].path"
          >
          <h3 class="article-title position-absolute top-50 start-50 translate-middle">{{ article.attributes.title }}</h3>
        </router-link>
      </div>
    </div>
    <div class="row px-sm-5 pb-5">
      <nav class="offset-sm-1 col-sm-10 px-sm-5" aria-label="navigation" v-if="articles.pagination?.total >= 0">
        <small>
          Mostrando desde <b>{{ articles.pagination.from }}</b> hasta <b>{{ articles.pagination.to }}</b>, de un total de <b>{{ articles.pagination.total }}</b> registro(s).
        </small>
        <ul class="pagination" v-if="articles.pagination.last_page >= 1">
          <template
            v-for="link in articles.pagination.links"
          >
            <li
              class="page-item"
              :key="link"
              v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
            >
              <a
                v-if="link.label !== '...'"
                href="#"
                :aria-label="link.label"
                :class="link.active ? 'bg-light' : 'bg-dark'"
                nowrap
                class="page-link"
                @click.prevent="link.label === '...' || articles.setPage(link.label).all()"
              >
                {{ link.label }}
              </a>
              <a 
                v-else
                class="page-link disable"
              >
                {{ link.label }}
              </a>
            </li>
          </template>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { ActiveRecordService } from '../../crud_maker/active_record'

export default {
  data () {
    return {
      moment: moment,
      articles: new ActiveRecordService()
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img6')
    this.$emit('loading', true)

    this.articles
      .from('articles')
      .relations(['images'])
      .paginate(5)
      .where('type', '=', 'proyecto')
      .orderBy("id", 'DESC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
        console.log(this.articles.items[0].attributes.images[0].path)
      })
      .all()
  }
}
</script>


<style scoped lang="scss">
.article-title {
  opacity: 0;
  z-index: 100;
  width: 50%;
  font-size: 42px;
  transition: 0.5s;
  padding: 28px;
}
.article:hover .article-title {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}
.article {
  position: relative;
}

.page-link {
  border: none !important;
  color: inherit;
  background-color: inherit;
}
</style>