<template>
  <div id="services" class="container-fluid text-light position-relative p-0">
    <img
      class="w-100 position-absolute d-none d-md-block"
      src="@/assets/img/blog/mod_white.png"
      style="
        top: -150px;
        right: -350px;
        max-width: 700px;
        z-index: 1000;
      "
    >
    <div class="container">
      <div class="row pb-5 mb-md-5">
        <div class="col-sm-8">
          <p id="title">EC Diseños está conformada por un equipo de profesionales interdisciplinarios,</p>
          <p class="fs-2">comprometidos con la excelencia, la creatividad, la innovación y el análisis de nuestras obras y proyectos.</p>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-black p-sm-5 pt-5">
      <div class="container pb-sm-5 px-sm-5">
        <div class="row">
          <div class="col-sm-12 px-5 pb-3">
            <h1 style="font-size:6vw;">Proyecto</h1>
          </div>
          <div class="d-block d-sm-none">
            <p>Estudios preliminares:</p>
            <ul>
              <li>Levantamiento Topográfico</li>
              <li>Estudio de Suelos</li>
            </ul>
            <hr>
            <p>Proyecto Arquitectónico:</p>
            <ul>
              <li>Diseño arquitectónico</li>
              <li>Diseño de Fachadas</li>
              <li>Diseño estructural</li>
              <li>Diseño de mobiliario fijo</li>
              <li>Diseño de iluminación</li>
              <li>Diseño de instalaciones eléctricas</li>
              <li>Diseño de instalaciones sanitarias</li>
              <li>Diseño de instalaciones mecánicas</li>
              <li>Diseño de instalaciones voz y data</li>
              <li>Diseño de instalaciones de seguridad</li>
            </ul>
            <hr>
            <p>Documentación Complementaria:</p>
            <ul>
              <li>Memoria Descriptiva</li>
              <li>Memoria de Cálculo</li>
              <li>Especificaciones Técnicas</li>
              <li>Cómputos Métricos</li>
            </ul>
          </div>
          <div class="row text-center">
            <div class="col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-dark bg-black">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_white.png">
                        <br>
                        Estudios preliminares:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Levantamiento Topográfico</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Estudio de Suelos</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-dark bg-black">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_white.png">
                        <br>
                        Proyecto Arquitectónico:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño arquitectónico</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de Fachadas</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño estructural</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de mobiliario fijo</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de iluminación</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de instalaciones eléctricas</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de instalaciones sanitarias</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de instalaciones mecánicas</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de instalaciones voz y data</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Diseño de instalaciones de seguridad</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-dark bg-black">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_white.png">
                        <br>
                        Documentación Complementaria:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Memoria Descriptiva</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Memoria de Cálculo</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Especificaciones Técnicas</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Cómputos Métricos</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-dark bg-black">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_white.png">
                        <br>
                        Visualización 3D:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Plantas tridimensionales</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Modelado 3D</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Render JPG</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-white p-sm-5 pt-5">
      <div class="container pb-5 px-sm-5">
        <div class="row">
          <div class="col-sm-12 px-5 pb-3">
            <h1 style="font-size:6vw;">Obra</h1>
          </div>
          <div class="d-block d-sm-none">
            <p>Construcción:</p>
            <ul>
              <li>Nivelación de terreno</li>
              <li>Infraestructura</li>
              <li>Superestructura</li>
              <li>Albañilería</li>
              <li>Acabados</li>
              <li>Herrería</li>
              <li>Carpintería</li>
              <li>Instalaciones</li>
            </ul>
            <hr>
            <p>Administración:</p>
            <ul>
              <li>Presupuesto de obra</li>
              <li>Planificación de obra</li>
              <li>Supervisión y control de obras</li>
            </ul>
          </div>
          <div class="row text-center">
            <div class="offset-3 col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-light">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle text-black" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_black.png">
                        <br>
                        Construcción:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Nivelación de terreno</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Infraestructura</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Superestructura</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Albañilería</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Acabados</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Herrería</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Carpintería</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Instalaciones</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="col-sm-3">
              <nav class="navbar navbar-expand-lg navbar-light">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mx-auto mb-2 mb-lg-0 ">
                    <li class="nav-item dropdown text-center">
                      <a class="nav-link dropdown-toggle text-black" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="img-icon" src="@/assets/img/blog/mod_black.png">
                        <br>
                        Administración:
                      </a>
                      <ul class="dropdown-menu bg-dark">
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Presupuesto de obra</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Planificación de obra</a></li>
                        <li><a class="dropdown-item text-white text-main-hover" href="#">Supervisión y control de obras</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  data () {
    return {
      moment: moment,
      articles: []
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img3')
    this.$emit('loading', false)
/*
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/articles?with=images,author&per_page=5&type=servicio&order_by=id:DESC`)
      .then((response) => {
        this.articles = response.data.data
        this.$emit('loading', false)
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error);
      })
*/
  }
}
</script>

<style scoped>
#title {
  font-size: 48px;
  line-height: 100%;
}

.img-icon {
  width: 64px;
  padding-bottom: 12px;
}
</style>