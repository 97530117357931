<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Acerca de</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Configuración</li>
              <li class="breadcrumb-item active">Acerca de</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Formulario</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.settings.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>

                  <button
                    class="btn btn-tool btn-secondary"
                    @click="model.reload()"
                  >
                    <font-awesome-icon icon="backspace" />
                    Resetear
                  </button>

                  <button
                    class="btn btn-tool btn-primary"
                    @click="save()"
                  >
                    <font-awesome-icon icon="save" />
                    Guardar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group offset-2 col-8">
                    <label for="value">Título: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="value"
                      name="value"
                      placeholder="Título"
                      v-model="model.attributes.value"
                      :class="model.errors['value'] ? 'is-invalid' : ''"
                    >
                    <template v-if="model.errors['value']">
                      <div
                        v-for="error in model.errors['value']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                    <div class="form-group offset-2 col-8">
                      <label for="email">Contenido: </label>
                      <Editor
                        api-key="no-api-key"
                        :init="{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                        }"
                        v-model="model.attributes.description"
                      />
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Editor from '@tinymce/tinymce-vue'
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  components: {
    Editor
  },
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        description: undefined,
        value: undefined,
      })
    }
  },
  mounted () {
    this.model
      .from('settings')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .load(5)
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          this.$router.push({ name: 'admin.settings.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  },
}
</script>