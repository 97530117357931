import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
  NavigationGuardWithThis
} from 'vue-router'
import PublicHome from '@/views/public/Home.vue'
import Portafolio from '@/views/public/Portafolio.vue'
import About from '@/views/public/About.vue'
import ContactUs from '@/views/public/ContactUs.vue'
import News from '@/views/public/News.vue'
import Services from '@/views/public/Services.vue'
import NewsDetail from '@/views/public/NewsDetail.vue'
import ProjectDetail from '@/views/public/ProjectDetail.vue'
import PublicNotFound from '@/views/public/NotFound.vue'
import Login from '@/views/public/Login.vue'

import AdminHome from '@/views/admin/Home.vue'

//import AdminNotFound from '@/views/admin/NotFound.vue'
import NewsList from '@/views/admin/news/List.vue'
import NewsForm from '@/views/admin/news/Form.vue'
import ServicesList from '@/views/admin/services/List.vue'
import ServicesForm from '@/views/admin/services/Form.vue'
import ProjectsList from '@/views/admin/projects/List.vue'
import ProjectsForm from '@/views/admin/projects/Form.vue'
import CategoryList from '@/views/admin/category/List.vue'
import CategoryForm from '@/views/admin/category/Form.vue'

import UserList from '@/views/admin/users/List.vue'
import UserForm from '@/views/admin/users/Form.vue'
import RolesList from '@/views/admin/roles/List.vue'
import RolesForm from '@/views/admin/roles/Form.vue'
import PermissionsList from '@/views/admin/permissions/List.vue'

import SettingsList from '@/views/admin/settings/List.vue'
import SettingsForm from '@/views/admin/settings/Form.vue'

import AboutUsForm from '@/views/admin/about_us/Form.vue'

import ContactsList from '@/views/admin/contacts/List.vue'
import ContactsForm from '@/views/admin/contacts/Form.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: PublicHome,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/portafolio',
    name: 'portafolio',
    component: Portafolio,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/noticias',
    name: 'news',
    component: News,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/noticias/:id/:slug',
    name: 'news.detail',
    component: NewsDetail,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/portafolio/:id/:slug',
    name: 'portafolio.detail',
    component: ProjectDetail,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: Login,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/admin',
    name: 'admin.home',
    redirect: { name: 'admin.news.index' },
    component: AdminHome,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/news',
    name: 'admin.news.index',
    component: NewsList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/news/create',
    name: 'admin.news.create',
    component: NewsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/news/:id/update',
    name: 'admin.news.update',
    component: NewsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/services',
    name: 'admin.services.index',
    component: ServicesList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/services/create',
    name: 'admin.services.create',
    component: ServicesForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/services/:id/update',
    name: 'admin.services.update',
    component: ServicesForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/projects',
    name: 'admin.projects.index',
    component: ProjectsList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/projects/create',
    name: 'admin.projects.create',
    component: ProjectsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/projects/:id/update',
    name: 'admin.projects.update',
    component: ProjectsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/categories',
    name: 'admin.categories.index',
    component: CategoryList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/categories/create',
    name: 'admin.categories.create',
    component: CategoryForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/categories/:id/update',
    name: 'admin.categories.update',
    component: CategoryForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/users',
    name: 'admin.access.users.index',
    component: UserList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/users/create',
    name: 'admin.access.users.create',
    component: UserForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/users/:id/update',
    name: 'admin.access.users.update',
    component: UserForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/roles',
    name: 'admin.access.roles.index',
    component: RolesList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/roles/create',
    name: 'admin.access.roles.create',
    component: RolesForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/roles/:id/update',
    name: 'admin.access.roles.update',
    component: RolesForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/access/permissions',
    name: 'admin.access.permissions.index',
    component: PermissionsList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/settings/',
    name: 'admin.settings.index',
    component: SettingsList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/settings/:id/update',
    name: 'admin.settings.update',
    component: SettingsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/about_us/update',
    name: 'admin.about_us.update',
    component: AboutUsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/contacts/',
    name: 'admin.contacts.index',
    component: ContactsList,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/admin/contacts/',
    name: 'admin.contacts.update',
    component: ContactsForm,
    meta: {
      layout: 'admin'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'public.not_found',
    component: PublicNotFound,
    meta: {
      layout: 'public'
    }
  },
  /*
  {
    path: '/admin/:pathMatch(.*)*',
    name: 'admin.not_found',
    component: AdminNotFound,
    meta: {
      layout: 'admin'
    }
  }
  */
]

const guard: NavigationGuardWithThis<undefined> = function(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  next()
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(guard)

export default router
