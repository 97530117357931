<template>
  <div id="login-form" class="container position-relative">
    <div class="row px-5 pt-5">
      <div class="offset-sm-4 col-sm-4 row px-sm-5 pt-5 bg-dark">
        <form @submit.prevent="login">
          <div class="form-group has-validation">
            <label class="form-label" for="email">Correo</label>
              
            <div class="input-group has-validation">
              <span class="input-group-text">@</span>
              <input
                name="email"
                type="email"
                class="form-control"
                :class="errors.email ? 'is-invalid' : ''"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Correo"
                required
                v-model="form.email"
                autocomplete="on"
              >
              <div
                v-if="errors.email"
                :class="errors.email ? 'invalid-feedback' : 'valid-feedback'"
              >
                {{ errors.email[0] }}
              </div>
            </div>
          </div>

          <div class="form-group pt-2">
            <label class="form-label" for="password">Contraseña</label>
            <input
              name="password"
              type="password"
              class="form-control"
              id="password"
              aria-describedby="passwordHelp"
              placeholder="Correo"
              required
              v-model="form.password"
              autocomplete="on"
            >
          </div>
          <div class="form-group pb-5 pt-3">
            <button type="submit" class="btn bg-main">Ingresar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data () {
    return {
      form: {
        email: null,
        password: null
      },
      errors: []
    }
  },
  methods: {
    login() {
      this.$emit('loading', true)
      axios.post(`${process.env.VUE_APP_API_URL}/api/login`, this.form)
      .then(response => {
        this.$emit('loading', false)

        localStorage.user = JSON.stringify(response.data)

        this.$router.push({ name: 'admin.news.index' })
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error.response?.data?.errors);
        this.errors = error.response?.data?.errors;
      })
    }
  },
  created () {
    if (localStorage.user) {
      this.$router.push({ name: 'admin.news.index' })
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-framed')
  }
}
</script>

<style lang="scss" scoped>
#login-form {
  color: white;
}
</style>