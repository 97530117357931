<template>

  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Categorías</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Artículos</li>
              <li class="breadcrumb-item">Categorías</li>
              <li class="breadcrumb-item active">Formulario</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Formulario</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.categories.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>

                  <button
                    class="btn btn-tool btn-secondary"
                    @click="model.reload()"
                  >
                    <font-awesome-icon icon="backspace" />
                    Resetear
                  </button>

                  <button
                    class="btn btn-tool btn-primary"
                    @click="save()"
                  >
                    <font-awesome-icon icon="save" />
                    Guardar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group offset-4 col-4">
                    <label for="name">Nombre: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="name"
                      name="name"
                      placeholder="Nombre"
                      v-model="model.attributes.name"
                      :class="model.errors['name'] ? 'is-invalid' : ''"
                    >
                    <template v-if="model.errors['name']">
                      <div
                        v-for="error in model.errors['name']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-4 col-4">
                    <label for="description">Descripción: </label>
                    <textarea
                      class="form-control form-control-border"
                      id="description"
                      name="description"
                      placeholder="Descripción"
                      v-model="model.attributes.description"
                      :class="model.errors['description'] ? 'is-invalid' : ''"
                    ></textarea>
                    <template v-if="model.errors['description']">
                      <div
                        v-for="error in model.errors['description']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>
                </form>
              </div>
              <div class="card-footer">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        description: undefined,
        permission_ids: [],
      })
    }
  },
  mounted () {
    this.model
      .from('categories')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
    if (this.$route.params.id) {
      this.model
        .load(this.$route.params.id)
    }
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          this.$router.push({ name: 'admin.categories.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  },
}
</script>