<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Roles</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Acceso</li>
              <li class="breadcrumb-item">Roles</li>
              <li class="breadcrumb-item active">Formulario</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Formulario</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.access.roles.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>

                  <button
                    class="btn btn-tool btn-secondary"
                    @click="model.reload()"
                  >
                    <font-awesome-icon icon="backspace" />
                    Resetear
                  </button>

                  <button
                    class="btn btn-tool btn-primary"
                    @click="save()"
                  >
                    <font-awesome-icon icon="save" />
                    Guardar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="name">Nombre: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="name"
                      name="name"
                      placeholder="Nombre"
                      v-model="model.attributes.name"
                      :class="model.errors['name'] ? 'is-invalid' : ''"
                    >
                    <template v-if="model.errors['name']">
                      <div
                        v-for="error in model.errors['name']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="description">Descripción: </label>
                    <textarea
                      type="text"
                      class="form-control form-control-border"
                      id="description"
                      name="description"
                      placeholder="Descripción"
                      v-model="model.attributes.description"
                      :class="model.errors['description'] ? 'is-invalid' : ''"
                    ></textarea>
                    <template v-if="model.errors['description']">
                      <div
                        v-for="error in model.errors['description']"
                        :key="error"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </template>
                  </div>

                  <div class="form-group offset-md-4 col-md-4 col-sm-12">
                    <label for="permissions">Permiso(s): </label>
                    <select
                      class="form-control form-control-border"
                      id="permission_ids"
                      name="permission_ids[]"
                      placeholder="Permisos"
                      v-model="model.attributes.permission_ids"
                      multiple="true"
                    >
                      <option
                        v-for="permission in permissions"
                        :key="permission.id"
                        :value="permission.id"
                      >
                        {{ permission.name }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios"
import { ActiveRecordService } from '../../../crud_maker/active_record'

export default {
  data () {
    return {
      permissions: [],
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        type: 'articulo',
        description: undefined,
        permission_ids: [],
      }),
    }
  },
  mounted () {
    this.model
      .from('roles')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter((response) => {
        this.$emit('loading', false)

        this.model.attributes.permission_ids = response.data.permissions.map(permission => permission.id)
      })

    axios.get(`${process.env.VUE_APP_API_URL}/api/permissions?per_page=0`)
      .then(response => {
        this.permissions = response.data.data

        if (this.$route.params.id) {
          this.model
            .relations(['permissions'])
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          this.$router.push({ name: 'admin.access.roles.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  }
}
</script>