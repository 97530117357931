<template>
  <div id="home" class="container-fluid text-light p-0">
    <div class="container" v-if="proyectos.items.length > 0">
      <div class="offset-sm-1 col-sm-10 px-sm-5 pt-5">
        <div class="col-12 py-5">
          <h1 class="text-main">EC DISEÑOS</h1>
          <h3>Proyectos & Obras destacados</h3>
        </div>
        <div class="col-12">
          <div class="row py-5 gx-sm-5" v-for="proyecto in proyectos.items" :key="proyecto.attributes.id">
            <div class="col-lg-7">
              <router-link
                class="text-light"
                :to="{name: 'portafolio.detail', params: { id: proyecto.attributes.id, slug: proyecto.attributes.slug }}"
              >
                <img :src="proyecto.attributes.images[0].path">
              </router-link>
            </div>
            <div class="offset-md-1 col-lg-4 align-middle">
              <h3>
                <router-link
                  class="text-main-hover"
                  :to="{name: 'portafolio.detail', params: { id: proyecto.attributes.id, slug: proyecto.attributes.slug }}"
                >
                  {{ proyecto.title }}
                </router-link>
              </h3>
              <p class="text-justify" v-html="proyecto.attributes.content.split('</p>')[0] + '</p>'"></p>
              <p>
                <router-link
                  class="text-main-hover"
                  :to="{name: 'portafolio.detail', params: { id: proyecto.attributes.id, slug: proyecto.attributes.slug }}"
                >
                  Leer más...
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 pb-4">
          <nav aria-label="navigation" v-if="proyectos.pagination?.total > 0">
            <small>
              Mostrando desde <b>{{ proyectos.pagination.from }}</b> hasta <b>{{ proyectos.pagination.to }}</b>, de un total de <b>{{ proyectos.pagination.total }}</b> registro(s).
            </small>
            <ul class="pagination" v-if="proyectos.pagination.last_page >= 1">
              <template
                v-for="link in proyectos.pagination.links"
              >
                <li
                  class="page-item"
                  :key="link"
                  v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
                >
                  <a
                    v-if="link.label !== '...'"
                    href="#"
                    :aria-label="link.label"
                    :class="link.active ? 'bg-light' : 'bg-dark'"
                    nowrap
                    class="page-link"
                    @click.prevent="link.label === '...' || proyectos.setPage(link.label).all()"
                  >
                    {{ link.label }}
                  </a>
                  <a 
                    v-else
                    class="page-link disable"
                  >
                    {{ link.label }}
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="row d-xs-none d-sm-block">
      <img class="w-100 p-0" src="@/assets/img/cintillo.png">
    </div>
    <div class="row bg-white py-5" v-if="articles.items.length > 0">
      <div class="offset-sm-1 col-sm-10 px-sm-5 container">
        <div class="row pb-2">
          <h3>Nuestros últimos artículos...</h3>
        </div>
        <div class="row gx-sm-5">
          <div
            class="col-lg-4 position-relative pb-sm-5 m-sm-0"
            v-for="article in articles.items"
            :key="article.attributes?.id"
          >
              <router-link
                class="text-dark"
                :to="{name: 'news.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
              >
                <img :src="article.attributes.images[0].path">
              </router-link>
              <h3 :title="article.title" style="overflow: hidden;">
                <router-link
                  class="text-dark"
                  :to="{name: 'news.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
                >
                  {{ article.attributes.title.slice(0, 40) }}...
                </router-link>
              </h3>
              <p class="text-justify" v-html="article.attributes?.content?.split('</p>')[0] + '</p>'"></p>
              <p class="position-absolute bottom-0">
                <router-link
                  class="text-main-hover text-dark"
                  :to="{name: 'news.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
                >
                  Leer más...
                </router-link>
              </p>
          </div>
        </div>
        <div class="row">
          <nav aria-label="navigation" v-if="articles.pagination?.total > 0">
            <small>
              Mostrando desde <b>{{ articles.pagination.from }}</b> hasta <b>{{ articles.pagination.to }}</b>, de un total de <b>{{ articles.pagination.total }}</b> registro(s).
            </small>
            <ul class="pagination" v-if="articles.pagination.last_page >= 1">
              <template
                v-for="link in articles.pagination.links"
              >
                <li
                  class="page-item"
                  :key="link"
                  v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
                >
                  <a
                    v-if="link.label !== '...'"
                    href="#"
                    :aria-label="link.label"
                    nowrap
                    class="page-link bg-dark"
                    @click.prevent="link.label === '...' || articles.setPage(link.label).all()"
                  >
                    {{ link.label }}
                  </a>
                  <a 
                    v-else
                    class="page-link disable"
                  >
                    {{ link.label }}
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import { ActiveRecordService } from '../../crud_maker/active_record'

export default {
  data () {
    return {
      moment: moment,
      proyectos: new ActiveRecordService(),
      articles: new ActiveRecordService()
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img')
    this.$emit('loading', true)

    this.proyectos
      .from('articles')
      .relations(['images'])
      .paginate(5)
      .where('type', '=', 'proyecto')
      .where('highlighted', '=', '1')
      .orderBy("id", 'DESC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()

    this.articles
      .from('articles')
      .relations(['images'])
      .paginate(3)
      .where('type', '=', 'articulo')
      .where('highlighted', '=', '1')
      .orderBy("id", 'DESC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()
  },
  filters: {
    stripHTML: (value) => {
      let regex = /(<([^>]+)>)/ig;
      return value?.replace(regex, "");
    }
  }
}
</script>


<style scoped lang="scss">
img {
  max-width: 100%;
  height: auto;
}

.page-link {
  border: none !important;
  color: inherit;
  background-color: inherit;
}
</style>