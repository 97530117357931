<template>
  <header id="header-container" class="container-fluid position-relative">
    <div class="container">
      <div id="header" class="offset-sm-1 col-sm-10">
        <nav class="navbar navbar-expand-lg row">
          <div class="navbar-brand col col-md-2 p-0">
            <router-link class="nav-link" :to="{ name: 'home' }">
              <img src="@/assets/img/logo.png" alt="">
            </router-link>
          </div>
          <div class="navbar-brand col col-md-10 d-md-block d-lg-none p-0">
            <a href="#" class="nav-link" @click.prevent="toggleMenu()">
              <font-awesome-icon icon="bars" />
            </a>
          </div>
          <div class="col-sm-10 text-center" :class="menu ? 'open w-xs-100 bg-dark border' : ' d-none d-lg-block'" style="z-index: 1000;">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link nowrap" active-class="active" :to="{ name: 'home' }">INICIO</router-link>
              </li>
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link" active-class="active" :to="{ name: 'about' }">NOSOTROS</router-link>
              </li>
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link" active-class="active" :to="{ name: 'services' }">SERVICIOS</router-link>
              </li>
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link" active-class="active" :to="{ name: 'portafolio' }">PORTAFOLIO</router-link>
              </li>
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link" active-class="active" :to="{ name: 'news' }">BLOG</router-link>
              </li>
              <li class="nav-item" nowrap @click="closeMenu()">
                <router-link class="nav-link" active-class="active" :to="{ name: 'contact-us' }">CONTACTO</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      menu: false,
    }
  },
  methods: {
    toggleMenu() {
      if (this.menu) {
        this.menu = false
      } else {
        this.menu = true
      }
    },
    closeMenu() {
      this.menu = false
    }
  },
}
</script>

<style scoped lang="scss">
header#header-container {
  height: 113px;
  padding: 40px 0 28px;
}

.navbar-brand .nav-link{
  font-size: 22px;
}

.navbar-brand .nav-link,
.nav-link.active,
.nav-item:hover .nav-link {
  color: white !important;
  text-decoration: none;
}

.nav-link {
  color: #808080 !important;
  font-size: 14px;
}


.navbar-brand {
  .nav-link {
    img {
      width: 180px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar-brand {
    .nav-link {
      img {
        width: 80px;
        height: auto;
      }
    }
  }
}

#logo {
  border: 3px solid white;
  letter-spacing: 0.1em;
  padding: 6px 12px;
}

.open {
  background-color: black;
  z-index: 100;
}
</style>