<template>
  <div class="h-100">
    <div
      id="spinner" 
      :class="isLoading ? '' : 'hidden'"
      @click="toggleMenu()"
    >
      <div
        class="spinner-border position-absolute start-50 top-50"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <component :is="layout === 'public' ? 'PublicLayout' : 'AdminLayout'" v-on:loading="setSpinner"/>
  </div>
</template>

<script>
import PublicLayout from './components/public/layouts/PublicLayout.vue'
import AdminLayout from './components/admin/layout/AdminLayout.vue'

export default {
  components: {
    PublicLayout,
    AdminLayout
  },
  data () {
    return {
      layout: 'public',
      isLoading: false
    }
  },
  methods: {
    setSpinner(value) {
      this.isLoading = value;
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-open')
    }
  },
  watch: {
    $route (to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = 'public'
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/bootstrap.scss";
</style>
