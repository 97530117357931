<template>
  <div class="container-fluid">
    <div class="row d-xs-none d-sm-block">
      <!--<img class="w-100 p-0" src="@/assets/img/cintillo-blog.png">-->
    </div>
    <div id="news" class="container text-light" v-if="articles.items.length > 0">
      <h1 class="text-xxl text-center fs-1 py-5">Experiencia en obras y proyectos arquitectónicos</h1>
      <h4 class="text-xl text-center">Descubre este maravilloso mundo de la Arquitectura...</h4>
      <div class="row px-sm-5">
        <div class="col-12 pt-5 pb-3">
          <h3>Noticias</h3>
        </div>
        
        <div class="row border border-light p-0 gx-0 mb-5" v-for="article in articles.items" :key="article.id">
          <div class="col-md-6">
            <router-link
              class="text-light"
              :to="{name: 'news.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
            >
              <img class="w-100 h-100" :src="article.attributes.images[0].path" v-if="article.attributes.images?.length > 0">
            </router-link>
          </div>
          <div class="col-md-6 px-md-5 p-3">
            <p><small>{{ article.attributes.author?.name }}</small></p>
            <h3 class="text-main">
              <router-link
                class="text-main-hover"
                :to="{name: 'news.detail', params: { id: article.attributes.id, slug: article.attributes.slug }}"
              >
              {{ article.attributes.title }}
              </router-link>
            </h3>
            <p class="fs-6 fw-lighter py-md-0" v-html="article.attributes.content?.split('</p>')[0] + '</p>'"></p>
          </div>
        </div>
      </div>
      
      <div class="row px-sm-5 pb-5">
        <nav aria-label="navigation" v-if="articles.pagination?.total >= 0">
          <small>
            Mostrando desde <b>{{ articles.pagination.from }}</b> hasta <b>{{ articles.pagination.to }}</b>, de un total de <b>{{ articles.pagination.total }}</b> registro(s).
          </small>
          <ul class="pagination" v-if="articles.pagination.last_page >= 1">
            <template
              v-for="link in articles.pagination.links"
            >
              <li
                class="page-item"
                :key="link"
                v-if="!link.label.includes('Previous') && !link.label.includes('Next')"
              >
                <a
                  v-if="link.label !== '...'"
                  href="#"
                  :aria-label="link.label"
                  :class="link.active ? 'bg-light' : 'bg-dark'"
                  nowrap
                  class="page-link"
                  @click.prevent="link.label === '...' || articles.setPage(link.label).all()"
                >
                  {{ link.label }}
                </a>
                <a 
                  v-else
                  class="page-link disable"
                >
                  {{ link.label }}
                </a>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import { ActiveRecordService } from '../../crud_maker/active_record'

export default {
  data () {
    return {
      moment: moment,
      articles: new ActiveRecordService()
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img5')
    this.$emit('loading', true)

    this.articles
      .from('articles')
      .relations(['images'])
      .paginate(5)
      .where('type', '=', 'articulo')
      .orderBy("id", 'DESC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()
  }
}
</script>

<style scoped lang="scss">
.page-link {
  border: none !important;
  color: inherit;
  background-color: inherit;
}
</style>