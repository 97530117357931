<template>
  <footer class="bg-black container-fluid p-5 position-relative">
    <div class="container">
      <div class="offset-md-1 col-md-10 px-sm-5 gx-sm-5">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-12 pb-sm-5 pt-5">
                <img src="@/assets/img/logo.png" alt="" style="width: 180px; height: auto">
                <br>
                <span>Si quieres bienestar disfruta <br> de la arquitectura EC Diseños<br></span>
                <span class="fs-3">#ArqByECDiseños</span> 
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <form id="contact-us-form" class="row">
              <div class="col-sm-12 pb-sm-5 pt-5">
                ESCRÍBENOS:
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name">Nombre *</label>
                  <input
                    id="name"
                    name="name"
                    class="form-control text-white"
                    type="text" 
                    style="background: transparent"
                    v-model="item.name"
                    :class="errors['name'] ? 'is-invalid' : ''"
                  >
                  <template v-if="errors['name']">
                    <div
                      v-for="error in errors['name']"
                      :key="error"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </template>
                </div>
                <div class="form-group">
                  <label for="email">Correo *</label>
                  <input
                    id="email"
                    name="email"
                    class="form-control text-light"
                    type="email"
                    style="background: transparent"
                    v-model="item.email"
                    :class="errors['email'] ? 'is-invalid' : ''"
                  >
                  <template v-if="errors['email']">
                    <div
                      v-for="error in errors['email']"
                      :key="error"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="message">Mensaje *</label>
                  <textarea
                    id="message"
                    name="message"
                    class="form-control text-white"
                    style="background: transparent"
                    rows="4"
                    v-model="item.message"
                    :class="errors['message'] ? 'is-invalid' : ''"
                  ></textarea>
                  <template v-if="errors['message']">
                    <div
                      v-for="error in errors['message']"
                      :key="error"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-sm-12 pt-2">
                <input id="submit" class="btn float-end px-4 bg-light" type="submit" value="Enviar" @click.prevent="send()">
              </div>
            </form>
          </div>
          <div class="col-sm-12 text-center pt-4">
            {{ this.$parent.settings?.correo }} | {{ this.$parent.settings?.telefono }} | {{ this.$parent.settings?.direccion }}
          </div>
          <div class="col-sm-12">
            <span class="float-start">
            © {{ this.$parent.settings?.copyright }}
            </span>
            <span class="float-end">
              <span>Síguenos en las redes</span>
              &nbsp; 
              &nbsp; 
              &nbsp; 
              <a 
                v-if="this.$parent.settings?.facebook"
                :href="`https://facebook.com/${this.$parent.settings?.facebook}`"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'facebook']" />
              </a>
              <a 
                v-if="this.$parent.settings?.twitter"
                :href="`https://twitter.com/${this.$parent.settings?.twitter}`"
                target="_blank"
              >
                
                &nbsp;<font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
              <a 
                v-if="this.$parent.settings?.instagram"
                :href="`https://instagram.com/${this.$parent.settings?.instagram}`"
                target="_blank"
              >
                &nbsp;<font-awesome-icon :icon="['fab', 'instagram']" />
              </a>
              <a 
                v-if="this.$parent.settings?.whatsapp"
                :href="`https://wa.me/${this.$parent.settings?.whatsapp}`"
                target="_blank"
              >
                &nbsp;<font-awesome-icon :icon="['fab', 'whatsapp']" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  data () {
    return {
      item: {
        name: undefined,
        email: undefined,
        organization: undefined,
        message: undefined
      },
      errors: {}
    }
  },
  mounted () {
  },
  methods: {
    send() {
      this.$emit('loading', true)
      axios.post(`${process.env.VUE_APP_API_URL}/api/contacts`, this.item)
        .then(response => {
          this.$forceUpdate()

          this.errors = {}

          this.item = {
            name: undefined,
            email: undefined,
            organization: undefined,
            message: undefined
          }
          this.$emit('loading', false)

          Swal.fire({
            title: 'Enviado',
            text: 'Sus datos han sido enviados de forma satisfactoria.',
            icon: 'success',
            confirmButtonText: 'Cerrar'
          })
        })
        .catch(error => {
          this.errors = error.response?.data?.errors || {}
          this.$emit('loading', false)
        })
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  color: white;
  a {
    color: inherit
  }
}
</style>