<template>
<div class="wrapper">
  <Navbar />


  <Sidebar />


  <router-view v-on:loading="loading"  @click="toggleMenu()"/>


  <Footer />
</div>
</template>

<script>
import Navbar from './Navbar.vue'
import Sidebar from './Sidebar.vue'
import Footer from './Footer.vue'

export default {
  name: 'AdminLayout',
  components: {
    Navbar,
    Sidebar,
    Footer
  },
  data () {
    return {
      //
    }
  },
  methods: {
    loading(boolean) {
      this.$emit('loading', boolean)
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-open')
    }
  },
  mounted () {
    if (!localStorage.user) {
      this.$router.push({ name: 'admin.login' })
    }

    document.title = "Administrador - EC Diseños"
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/css/adminlte.min.css";
</style>
