<template>
  <div id="layout-container" class="position-relative" :class="bg || 'bg-dark'">
    <Header></Header>
    <main id="public-layout" class="container-fluid px-0">
      <div id="content">
        <router-view v-on:showBgImg="setBg" v-on:loading="loading" />
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import axios from "axios"

export default {
  name: 'PublicLayout',
  components : {
    'Footer': Footer,
    'Header': Header,
  },

  data () {
    return {
      bg: false,
      settings: {},
    }
  },
  mounted () {
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/settings?order_by=id:ASC&internal=false&per_page=0`)
      .then((response) => {
        for (const setting of response.data.data) {
          this.settings[setting.slug] = setting.value;
        }

        document.title = `${this.settings?.siglas} ${this.settings?.titulo}` || "EC Diseños"
      }).catch(error => {
        console.log(error);
      })
  },  
  methods: {
    setBg(value) {
      this.bg = value;
    },
    loading(boolean) {
      this.$emit('loading', boolean)
    }
  }
}
</script>
