import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap' 
import axios from 'axios'
import VueAxios from 'vue-axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import {    
    faSignOutAlt,
    faBars,
    faCircle,
    faAngleLeft,
    faAngleDown,
    faPlus,
    faEdit,
    faTrashAlt,
    faSave,
    faBackspace,
    faUndo,
    faHome,
    faSort,
    faSortUp,
    faSortDown,
    faFilter,
    faEye
} from '@fortawesome/free-solid-svg-icons'
import { Multiselect } from 'vue-multiselect'
import { createI18n } from 'vue-i18n'

const messages = {
  es: require('./assets/lang/es.json')
}

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages,
})

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(i18n)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('multiselect', Multiselect)


app.config.globalProperties.$filters = {
    stripHTML(value: string): string {
        let regex = /(<([^>]+)>)/ig;
        return value?.replace(regex, "");
    }
}


library.add(
    faFacebook,
    faTwitter,
    faInstagram,
    faWhatsapp,

    faSignOutAlt,
    faBars,
    faCircle,
    faAngleLeft,
    faAngleDown,
    faPlus,
    faEdit,
    faEye,
    faTrashAlt,
    faSave,
    faBackspace,
    faUndo,
    faHome,
    faSort,
    faSortUp,
    faSortDown,
    faFilter,
    faBars
);

app.mount('#app')
