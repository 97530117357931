<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/admin" class="brand-link elevation-4">
      <img src="@/assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">Dashboard</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <a href="#" class="d-block">{{ access?.user?.name }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <!--
          <li class="nav-item">
            <router-link :to="{ name: 'admin.home' }" class="nav-link">
              <i class="nav-icon far fa-image"></i>
              <p>
                Inicio
              </p>
            </router-link>
          </li>
          -->
          <li class="nav-item" :class="sidebar.open === 'articles' && 'menu-open'">
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'articles' && 'active'"
              @click.prevent="collapse('articles')"
            >
              <font-awesome-icon :icon="sidebar.open === 'articles' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>
                Artículos
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{ name: 'admin.news.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Noticias</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin.projects.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Portafolio</p>
                </router-link>
              </li>
              <!--
              <li class="nav-item">
                <router-link :to="{ name: 'admin.services.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Servicios</p>
                </router-link>
              </li>
              -->
              <li class="nav-item">
                <router-link :to="{ name: 'admin.categories.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Categorías</p>
                </router-link>
              </li>
            </ul>
          </li>
          <hr>
          <li class="nav-header">OPCIONES</li>
          <li class="nav-item" :class="sidebar.open === 'access' && 'menu-open'">
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'access' && 'active'"
              @click.prevent="collapse('access')"
            >
              <font-awesome-icon :icon="sidebar.open === 'access' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>
                Acceso
              </p>
            </a>
            
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{ name: 'admin.access.users.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Usuarios</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin.access.roles.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Roles</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin.access.permissions.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Permisos</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" :class="sidebar.open === 'settings' && 'menu-open'">
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'settings' && 'active'"
              @click.prevent="collapse('settings')"
            >
              <font-awesome-icon :icon="sidebar.open === 'settings' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>
                Configuración
              </p>
            </a>
            
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.index' }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Listado</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.about_us.update' }"
                  class="nav-link"
                  active-class="active"
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Acerca de</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.contacts.index' }"
                  class="nav-link"
                  active-class="active"
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Contactos</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      sidebar: {
        open: null
      },
      access: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    collapse: function (name) {
      if (this.sidebar.open === name) {
        this.sidebar.open = null
      } else {
        this.sidebar.open = name
      }
    }
  },
  mounted () {
    if (!localStorage.user) {
      this.$router.push({ name: 'admin.login' })
    }
  }
}
</script>