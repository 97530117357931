<template>
  <div id="not-found" class="container px-5 pb-5 mb-5">
    <div class="row px-5">
      <div class="offset-1 col-10 p-5 text-center">
        <h1>Página no encontrada</h1>
        <h3>404</h3>
        <router-link class="text-light fs-3" :to="{ name: 'home' }">Volver al inicio</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$emit('showBgImg', 'bg-dark')
    this.$emit('loading', false)
  }
}
</script>

<style scoped lang="scss">
#not-found {
  color: white;
}
</style>