<template>
  <div class="container text-light px-sm-5" v-if="article">
    <div class="row px-5 pt-5">
      <div id="framed-container" class="offset-sm-1 col-sm-10 row px-sm-5 pt-5">
        <p>{{ article.author?.name }}</p>
        <h3>{{ article.title }}</h3>
        <img
          v-if="article.images"
          :src="article.images[0].path"
          class="py-5"
        >
        <div id="article-content" v-html="article.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  data () {
    return {
      moment: moment,
      article: []
    }
  },
  mounted () {
    this.$emit('showBgImg', 'bg-img5')
    this.$emit('loading', true)

    axios
      .get(`${process.env.VUE_APP_API_URL}/api/articles/${this.$route.params.id}?with=images,author,category,comments`)
      .then((response) => {
        this.article = response.data
        if (response.data.slug !== this.$route.params.slug) {
          this.$router.push({ name: 'public.not_found' })
        }
        this.$emit('loading', false)
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error);
      })
  }
}
</script>
